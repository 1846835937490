const flipToggleMulti = () => {
    let flipToggle2 = document.querySelectorAll(".multi-flip-toggle");
    let isEditMode = document.querySelector(".multi-flip-container");

    if (isEditMode) {
        isEditMode = isEditMode.classList.contains("editMode");
        let btns = document.querySelectorAll(".editMode .box a.btn");
        let btns1 = document.querySelectorAll(".author-editor .box a.btn");
        setTimeout(function() {
            btns.forEach(el => {
                el.classList.remove("disabled");
            });
            btns1.forEach(el => {
                el.classList.remove("disabled");
            });
        }, 2000);
    }

    flipToggle2.forEach(el => {
        el.onclick = e => {
            e.preventDefault();
            document.querySelector(".multi-flip-container").classList.toggle("active");
            document.querySelector(".multi-flip-container").setAttribute("data-back-active", el.getAttribute("href"));

            document.querySelectorAll(".multi-flip-container .back").forEach(el => {
                el.classList.remove("showBack");
            });
            document.querySelectorAll(".multi-flip-container .box").forEach(boxItem => {
                boxItem.setAttribute("aria-expanded", false);
            });
            let showBack = document.querySelector("[data-back-active]").getAttribute("data-back-active");
            document.querySelector(showBack).classList.add("showBack");
            document.querySelector(showBack).setAttribute("aria-expanded", true);
            // if the back button is clicked, then add active focus on front button to fix accessibility issue
            if (el.closest(".box").classList.contains("back")) {
                const anchors = document.querySelectorAll("#front1 a.btn-link");
                const matchingAnchor = Array.from(anchors).filter(anchor => anchor.getAttribute("href") === "#" + el.closest(".box.back").id);
                setTimeout(function() {
                    matchingAnchor[0].focus();
                }, 500);
            }
        };
    });
};

export default flipToggleMulti;
